
import { mapActions, mapGetters } from 'vuex';
// import { i18n } from '@/i18n';
import i18n from "@/vueI18n";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
// import Message from '@/shared/message/message';


import firebase from 'firebase';
import 'firebase/firestore';
export default {
  components: {
    VuePhoneNumberInput,
  },

data(){
  return{
    disableFirstName:true,
    disableLastName:true,
    // firstname:'',
    // lastname:'',
    fullName:null,
    email:'',
    phone:[],
    phones:[],
    finds:[],
    counter:0,
    additionalPhones:0,
    jobs:[],
    properties:[],
    prescriptions:[],
    rawFile:'',
    path:'',
    downloadURL:'',
    file:'',
    phoneNumber:'',
    editFullName: true,
    editFirstName: true,
    editLastName: true,
    editEmail: true,
    editPhoneNumber: true,
    fileExist: false,
    validEmail: false,
    validPhone: false,
    progress:null,
    btn_disabled:false,
    phoneResponse:null,
    // offerNotification: true,
    confirm:false,
    verified:false,
    loadingUploadImage: false,

      code: '',
      countryCode: '+20',
      countryName: 'EG',
      isValid: false,
      errorMessage: '',
      phoneLenght: 8,
      phoneMask: '#########',
      TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choose a country',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'اختر دولة',
        phoneNumberLabel: 'رقم الجوال',
        example: 'مثال :',
      },
      

  }
},
computed:{
  lang(){
      // debugger    
      return i18n.locale
    },
...mapGetters({
    navbar:'layout/navbar',
    currentUser: 'auth/currentUser',
    loadingUpdateProfile:'auth/loadingUpdateProfile',
    userListener:'auth/userListener'
}),
progressBar(){
  return this.progress / 100
},
isRTL() {
      return localStorage.getItem('language') == 'ar';
    },

},
methods:{
  onPhoneUpdate(data) {
      this.countryCode = '+' + data.countryCallingCode;
      this.countryName = data.countryCode
      this.isValid = data.isValid
      this.errorMessage = ''
      this.validPhone = data.isValid
      // this.validatePhone(data.formattedNumber)
    },

  goToMyJobs(){
    const lang = localStorage.getItem('language') || 'en'
    this.$router.push({
      name:'my-jobs',
      params:{
        lang
      },
      query:{
        page: 1
      }})
  },
  goToMyFacilities(){
    const lang = localStorage.getItem('language') || 'en'
    this.$router.push({
      name:'my-facilities',
      params:{
        lang
      },
      query:{
        page: 1
      }})
  },
  goToMyPrescriptions(){
    const lang = localStorage.getItem('language') || 'en'
    this.$router.push({
      name:'my-prescriptions',
      params:{
        lang
      },
      query:{
        page: 1
      }})
  },


      handleOnCompleteVerifiy(value) {
      if (this.hasNumber(value)) {
        this.code = value
        this.error = '';
        this.verified = true
      } else {
        this.error = this.i18n('auth.error.verificationCodeShouldOnlyBeNumbers');
        this.code = ''
        this.verified = false
      }
    },
    handleOnChangeVerifiy(value){
      if (value.length > 0) {
        this.error = '';
      }
      if (value.length < 6) {
        this.code = '';
        this.verified = false
      }
    },
    hasNumber(myString) {
      return /^\d+$/.test(myString);
    },
  validateEmail(email) {
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if(re.test(String(email).toLowerCase())){
            this.validEmail = true
            return true
        }
        else{
            this.validEmail = false
            return false
        }  
            
    },
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    // checkIfPhoneNumberIsDigit(){
    //   console.log();
    // },
    // validatePhone(){
    //   if(this.phoneNumber) return true
    //   return false

    //   // const phoneno = /^\+?[0-9]\d{1,14}$/
    //   // const phone = this.countryCode + this.phoneNumber
    //   // if (phoneno.test(phone)){
    //   //     this.validPhone = true
    //   //     return true; 
    //   // }
    //   // else {
    //   //     this.validPhone = false
    //   //     return false;
    //   // } 


    //   // const phoneno = /^\+?d{12}$/
    //   // const phoneno = /^\+?[0-9]\d{1,14}$/
    //   // if (phoneno.test(inputtxt)){
    //   //     this.validPhone = true
    //   //     return true; 
    //   // }
    //   // else {
    //   //     this.validPhone = false
    //   //     return false;
    //   // } 
    // },
  i18n(key, args) {
      return this.$t(key, args);
    },
  ...mapActions({
    setHeaderBg:'layout/setHeaderBg',
    setNavbar:'layout/setNavbar',
    setFooter:'layout/setFooter',
    doUpdateProfile: 'auth/doUpdateProfile'
  }),
  addPhoneInModel(){
    this.phones.push({ value: '' });
  },
  addPhone(){
    this.counter += 1
    this.additionalPhones += 1
    this.phones.push({ value: '' });
  },
  async linkPhone(){
    let appVerifier = window.recaptchaVerifier;
    // debugger
    if(!firebase.auth().currentUser.phoneNumber){
      firebase.auth().currentUser.linkWithPhoneNumber(this.countryCode + this.phoneNumber ,appVerifier).then(res => {
        this.phoneResponse = res
      })
      // .catch((error) =>{
      //   console.log('error',error);
      // })
    }
    // else if(firebase.auth().currentUser.phoneNumber !== this.phoneNumber){

    // }
  },
  doVerify(code){
    const lang = localStorage.getItem('language')
    // if(!this.offerNotification){
    //   delete this.currentUser.deviceTokens['web']
    //   console.log(this.currentUser.deviceTokens);
    //   // debugger
    // } 
    // else{
      this.currentUser.deviceTokens['web'] = lang
    //   console.log(this.currentUser.deviceTokens);
    //   // debugger
    // }
    this.phoneResponse.confirm(code).then(async () => {
      if(this.rawFile == ''){
        await this.doUpdateProfile({ 
          fullName: this.fullName, 
          phoneNumber: this.phoneNumber ,
          countryCode: this.countryCode, 
          countryName: this.countryName,
          avatar: this.downloadURL,
          deviceTokens: this.currentUser.deviceTokens
          
          })
          // Message.success(this.i18n('auth.profile.success'));
      }
      else{
        this.uploadFromRequest()
      }
    }).catch(async () =>{
      // await this.doUpdateProfile({ fullName: this.fullName, phoneNumber: this.phoneNumber , avatar: this.downloadURL })
      if(this.rawFile == ''){
        await this.doUpdateProfile({ 
          fullName: this.fullName, 
          phoneNumber: this.phoneNumber, 
          countryCode: this.countryCode,
          countryName: this.countryName,
          avatar: this.downloadURL,
          deviceTokens: this.currentUser.deviceTokens
        })
      }
      else{
        this.uploadFromRequest()
      }
    })
  },
 async doSubmit(){


    // debugger
    const lang = localStorage.getItem('language')

    // if(!this.offerNotification){
    //     delete this.currentUser.deviceTokens['web']
    //     console.log(this.currentUser.deviceTokens);
    //     // debugger
    // } 
    // else{
      this.currentUser.deviceTokens['web'] = lang
    //   console.log(this.currentUser.deviceTokens);
    //   // debugger
    // }

   
   if(this.currentUser.phoneNumber == this.phoneNumber){
    // debugger
    if(this.rawFile == ''){
      await this.doUpdateProfile({ 
        fullName: this.fullName, 
        phoneNumber: this.phoneNumber,
        countryCode: this.countryCode,
        countryName: this.countryName,
        avatar: this.downloadURL,
        deviceTokens: this.currentUser.deviceTokens 
      })
    }
    else{
      // debugger
      this.uploadFromRequest()
    }
    
   }
   else{
    //  console.log('in else');
    this.confirm = true
    this.linkPhone()
   }
  },
      openImageUpload(){
          const fileUpload = document.getElementById('file-upload')
          fileUpload.click()
      },
      async readUrl(event){
       const profilePicture = document.getElementById('profile-pic')
        if (event.target.files && event.target.files[0]) {
            this.rawFile = event.target.files[0]
            this.fileName = event.target.files[0].name
            var reader = new FileReader();
            reader.onload = function (e) {
                profilePicture.src =  e.target.result;
                this.file = e.target.result
            }
    
            reader.readAsDataURL(event.target.files[0]);
            this.fileExist = true
            // await this.uploadFromRequest()
        }
    },
    async uploadFromRequest() {
    this.btn_disabled = true
    //   console.log('schema : ',this.fields.avatarsIam.fileSchema)
    var storage = firebase.storage().ref();
    var uploadTask = storage.child(`user/avatars/profile/${this.currentUser.id}/${this.fileName}`).put(this.rawFile);
    await uploadTask.on('state_changed', 
        async (snapshot) => {
          this.loadingUploadImage = true
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.progress = parseInt(progress)
          if(this.progress === 100) this.btn_disabled = false
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              break;
          }
            // const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
            // console.log('ddd',downloadURL)
            // this.downloadURL = downloadURL
            // console.log('url', this.downloadURL);
        },
           (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
            this.loadingUploadImage = true
          },
        () =>{
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            this.downloadURL = downloadURL
            await this.doUpdateProfile({ 
              fullName: this.fullName, 
              phoneNumber: this.phoneNumber, 
              countryCode: this.countryCode,
              countryName: this.countryName,
              avatar: this.downloadURL,
              deviceTokens: this.currentUser.deviceTokens
            })
          })
          this.loadingUploadImage = false
        },
        );

    },
    
  
},
mounted(){
  window.recaptchaVerifier =  new firebase.auth.RecaptchaVerifier('recaptcha-div', {
      'size': 'invisible',
    });
  this.setHeaderBg('bg-shadow')
  this.setNavbar('login')
  this.setFooter(true)
  this.firstname = this.currentUser.firstName
  this.lastname = this.currentUser.lastName
  this.fullName = this.currentUser.fullName
  this.email = this.currentUser.email
  // this.offerNotification = 'web' in this.currentUser.deviceTokens ? true : false
  // this.phone.push(this.currentUser.phoneNumber)
  this.phoneNumber = this.currentUser.phoneNumber
  this.validPhone = true
  this.countryName = this.currentUser.countryName ? this.currentUser.countryName : 'EG'
  this.countryCode = this.currentUser.countryCode ? this.currentUser.countryCode : '+20'
  this.$q.iconSet.field.error = "img:/images/invalid-email.png"
 
},
async created(){
  document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('drugDeal.common.profile')    
},
watch:{
  // phones(value){
  //   console.log("all phones ",value)
  // },
  lang(){
        document.title =
          this.i18n('app.title') +
          ' | ' +
          this.i18n('drugDeal.common.profile') 
      }
}
}
